import axios from 'axios'

function getLocalTaxById(id) {
  return axios.get(`local-tax/${id}`)
}

function editLocalTax(id, data) {
  return axios.put(`local-tax/${id}`, data)
}

function getHotelListing(payload) {
  return axios.get('hotel/list', { params: payload })
}

export default {
  getLocalTaxById,
  editLocalTax,
  getHotelListing
}
