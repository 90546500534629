<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative overflow_table_cstm"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
      >
        <template #cell(resort)="data">
          {{ data.item.name }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="storeResortName(data.item.name, data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ totalResorts >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalResorts ? totalResorts : endIndex }} of
              {{ totalResorts }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalResorts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BCol,
  BFormInput
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  resConditionCheck,
  perPage,
  currentPage,
  perPageOptions
} from "@/helpers/constant";
// import hotelService from '@/services/hotel/hotel.service'
import localTaxService from "@/services/local-tax/localTax.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/skeloton-loader/Loader.vue";

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      items: [],
      startIndex: 0,
      endIndex: 0,
      tableColumns: [
        {
          key: "name",
          label: "Resort",
          sortable: true
        },
        { key: "actions" }
      ],
      currentPage,
      perPage,
      perPageOptions,
      searchQuery: "",
      sortBy: "",
      totalResorts: 0,
      isSortDirDesc: false
    };
  },

  watch: {
    currentPage: {
      handler(val) {
        this.getHotelListing();
        this.$router.push({name: 'local-tax', query: {page:val, rows:this.perPage}}).catch(()=>{});
      }
    },
    perPage: {
      handler(val) {
        // this.currentPage = 1;
        this.getHotelListing();
        this.$router.push({name: 'local-tax', query: {page:this.currentPage, rows:val}}).catch(()=>{});
      }
    },
    sortBy: {
      handler(newValue) {
        this.sortBy = newValue;
        this.getHotelListing();
      }
    },
    isSortDirDesc: {
      handler() {
        this.getHotelListing();
      }
    },

    searchQuery: {
      handler() {
        this.currentPage = 1;
        if (this.searchQuery.length >=3 || this.searchQuery.length == 0) {
          this.getHotelListing();
        }
      }
    }
  },

  async mounted() {
    await this.getHotelListing();
    if (this.$route.query) {
      this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
    }
  },

  methods: {
    storeResortName(name, id) {
      localStorage.setItem("resort_name", name);
      this.$router.push({ name: "local-tax-edit", params: { id }, query: {page: this.currentPage, rows: this.perPage} });
    },

    async getHotelListing() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery
      };

      try {
        const res = await localTaxService.getHotelListing(payload);
        if (resConditionCheck(res.status) && res.data.data) {
          this.contentLoading = false;
          this.items = res.data.data;
          this.totalResorts = res.data.count;
          this.startIndex = this.currentPage * this.perPage - this.perPage;
          this.endIndex = this.startIndex + this.perPage;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.contentLoading = false;
        if (error.response.status === 404) {
          this.items = [];
          this.totalResorts = 0;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
